<template>
  <div name="index">
    <head-box />
    
    <!-- <div v-if="!isIndex" @click.stop="$router.go(-1);" class="backBtn"> -->
    <div v-if="!isIndex" @click.stop="$router.go(-1)" class="backBtn">
      <span>{{ $t('public.goBack') }}</span>
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <!-- <keep-alive> -->
    <!-- 需要缓存的视图组件 -->
    <!-- <router-view v-if="$route.meta.keepAlive"></router-view> -->
    <!-- </keep-alive> -->
    <!-- 不需要缓存的视图组件 -->
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
import HeadBox from '../components/HeadBox/index'

export default {
  name: "app",
  components: { HeadBox },
  watch: {
    $route() {
      this.isIndex = this.$route.path === "/";
    },
  },
  data() {
    return {
      isIndex: true
    }
  },
  created() {},
  methods: {
    goBack() {
      const path = this.$route.path;
      const reg = /\//g;
      const c = path.match(reg);
      if (c.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>

.backBtn {
  position: fixed;
  right: 5%;
  top: 90%;
  z-index: 2000;
  opacity: 0.9;
}

.backBtn span {
  color: #b51414;
  font-size: 14px;
  background: #fe9b4a;
  padding: 0.3rem 0.8rem;
  border-radius: 0.5rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: bold;
}
</style>
